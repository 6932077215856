import React from 'react';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Pagination,
  FormControl,
  Theme,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { GridSettingsContext } from 'context';
import { FlexRow } from 'components';
import { useElementWidth } from 'hooks';

import { FooterLabel } from './grid-footer.styled';

interface Props {
  total: number;
  selected: number;
}

const GridFooter: React.FC<Props> = ({ total, selected }) => {
  const {
    gridSettings: { page, perPage },
    isInCompactView,
    isInColDnDMode,
    setIsInColDnDMode,
    setIsInCompactView,
    setGridSettingsItem,
  } = React.useContext(GridSettingsContext);
  const footerWidth = useElementWidth('flex-footer');
  const isSmallScreen = React.useMemo(() => footerWidth < 1120, [footerWidth]);

  const handlePerPageChange = (event: SelectChangeEvent<number>): void =>
    setGridSettingsItem('perPage', event.target.value as number, false);

  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => setGridSettingsItem('page', page, false);

  return (
    <Box id="flex-footer">
      {footerWidth === 0 ? null : (
        <FlexRow
          sx={{
            height: '53px',
            justifyContent: 'start',
            alignItems: 'center',
            px: '16px',
            backgroundColor: 'white',
            minWidth: '700px',
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" checked={isInCompactView} size={isSmallScreen ? 'small' : 'medium'} />}
              label="Compact view"
              onChange={() => setIsInCompactView(!isInCompactView)}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Switch color="primary" checked={isInColDnDMode} size={isSmallScreen ? 'small' : 'medium'} />}
              label="Columns reorder "
              onChange={() => setIsInColDnDMode(!isInColDnDMode)}
            />
          </FormGroup>
          <FlexRow sx={{ flex: 1, justifyContent: 'end' }}>
            <FooterLabel value={selected} label="Selected" isSmallScreen={isSmallScreen} />
            <FooterLabel value={total} label="Found" isSmallScreen={isSmallScreen} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: '12px',
                px: isSmallScreen ? '8px' : '12px',
                mr: isSmallScreen ? '8px' : '12px',
              }}
            >
              {!isSmallScreen && (
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '2px',
                    mr: '8px',
                  }}
                  color="primary"
                >
                  Per page:
                </Typography>
              )}

              <FormControl
                variant="standard"
                sx={{
                  mb: '-2px',
                  backgroundColor: 'transparent',
                  fontSize: '12px',
                  '&:focus': { backgroundColor: 'transparent' },
                }}
                color="primary"
              >
                <Select value={perPage} label="Age" disableUnderline onChange={handlePerPageChange}>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                  <MenuItem value={200}>200</MenuItem>
                  <MenuItem value={500}>500</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Pagination
              count={Math.ceil(total / perPage)}
              color="primary"
              page={page}
              onChange={handlePageChange}
              size={isSmallScreen ? 'small' : 'medium'}
            />
          </FlexRow>
        </FlexRow>
      )}
    </Box>
  );
};

export default GridFooter;
