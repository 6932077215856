import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Box, IconButton } from '@mui/material';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import { Autocomplete } from 'components';
import { useCandidateGet, useFetchCandidatesByFullName } from 'api';
import { Candidate, SelectItem } from 'types';
import { Routes } from 'config';

interface ReferredByFilterProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const ReferredByFilter = ({ value, onChange }: ReferredByFilterProps) => {
  const [fullName, setFullName] = useState<string>('');

  const { candidate: referral } = useCandidateGet(value);

  const referralFullName = useMemo(() => (referral ? `${referral.firstName} ${referral.lastName}` : ''), [referral]);

  const { data: foundDevelopers } = useFetchCandidatesByFullName({ fullName });

  const items = useMemo<Array<SelectItem<string>>>(
    () =>
      (foundDevelopers ? foundDevelopers : []).map((dev) => ({
        value: dev.id,
        name: `${dev.firstName} ${dev.lastName}`,
      })),
    [foundDevelopers]
  );

  const openReferralProfile = useCallback(() => {
    window.open(`${Routes.profile}/${(referral as Candidate).id}`);
  }, [referral]);

  useEffect(() => {
    setFullName(referral ? referralFullName : '');
  }, [referral, referralFullName]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Autocomplete
        label="Referred by"
        color="primary"
        variant="outlined"
        items={items}
        value={value}
        onChange={onChange}
      />
      {value && (
        <IconButton onClick={openReferralProfile}>
          <LaunchOutlinedIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ReferredByFilter;
