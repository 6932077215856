import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const titleContainerPath =
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent';

export const CandidatesGridStyled = styled(DataGridPro)(() => ({
  '&.MuiDataGrid-root': {
    borderRadius: 0,
    borderLeft: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  [titleContainerPath]: {
    width: '100%',
  },
}));

export const GridContainerStyled = styled(Box)(() => ({
  width: '100%',
  backgroundColor: 'white',
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
}));
