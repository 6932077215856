import { useCallback, useContext, useMemo } from 'react';
import { Box } from '@mui/material';

import { GridSettingsContext } from 'context';
import { RadioButtonGroup, ResettableSection, Autocomplete, MultiAutocomplete, CheckboxWithLabel } from 'components';
import { useFetchConstants, useFindTags } from 'api';
import { CampaignTagCategory, GridSettings, LeadTypeOptionValue, SelectItem, TagGroup } from 'types';
import { makeSelectItems } from 'utils';

import ReferredByFilter from './referred-by-filter';

const AdvancedFilters = () => {
  const { data: constants } = useFetchConstants();

  const {
    gridSettings: {
      leadType,
      campaignTagsInclude,
      campaignTagsExclude,
      forFutureReasonsInclude,
      forFutureReasonsExclude,
      selfAssessmentInclude,
      commercialExperience,
      relevantCountriesOnly,
      referredBySomeone,
      referredById,
    },
    setGridSettingsItem,
    resetAdvancedFilters,
  } = useContext(GridSettingsContext);

  const { findTagsResult: campaignTags } = useFindTags({
    filters: {
      isDeleted: false,
      categories: [CampaignTagCategory.Reactivation],
      groups: [TagGroup.Campaign],
    },
    sorting: { createdDate: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  });

  const campaignTagItems = useMemo<Array<SelectItem<string>>>(
    () =>
      (campaignTags?.tags ?? []).map(({ name, id }) => {
        return { name, value: id };
      }),
    [campaignTags]
  );

  const leadTypeItems = useMemo<Array<SelectItem<string>>>(
    () => [
      { value: LeadTypeOptionValue.ALL_LEADS, name: 'All leads' },
      { value: LeadTypeOptionValue.INBOUND, name: 'Inbound' },
      { value: LeadTypeOptionValue.OUTREACHED, name: 'Outreached' },
    ],
    []
  );

  const makeChangeHandler =
    <K extends keyof GridSettings>(gridSettingsItem: K) =>
    (value: GridSettings[K]) =>
      setGridSettingsItem(gridSettingsItem, value);

  const forFutureReasonsItems = useMemo(
    () => makeSelectItems([...(constants?.disqualificationReasons ?? [])]),
    [constants]
  );

  const seniorityItems = useMemo(() => makeSelectItems(constants?.seniorityLevelsList ?? []), [constants]);

  const commercialExperienceItems = useMemo<Array<SelectItem<string>>>(
    () => [
      { name: '1 year', value: '1' },
      ...Array.from({ length: 9 }, (_, i) => i + 2).map((years) => ({
        name: `${years} years`,
        value: String(years),
      })),
    ],
    []
  );

  const handleCommercialExperienceChange = useCallback(
    (value: string | null) => {
      const valueToSet = value ? Number(value) : null;
      setGridSettingsItem('commercialExperience', valueToSet);
    },
    [setGridSettingsItem]
  );

  return (
    <Box sx={{ px: '16px' }}>
      <ResettableSection sx={{ pr: '4px', mb: '8px' }} title="Advanced filters" onReset={resetAdvancedFilters} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <MultiAutocomplete
          label="Reactivation campaign"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={campaignTagItems}
          value={campaignTagsInclude}
          disabledItems={campaignTagsExclude}
          onChange={makeChangeHandler('campaignTagsInclude')}
        />
        <MultiAutocomplete
          label="Exclude reactivation campaigns"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={campaignTagItems}
          value={campaignTagsExclude}
          disabledItems={campaignTagsInclude}
          onChange={makeChangeHandler('campaignTagsExclude')}
        />
        <RadioButtonGroup
          buttons={leadTypeItems}
          initialSelection={leadType}
          onChange={makeChangeHandler('leadType')}
        />
        <MultiAutocomplete
          label="Include devs with For future reasons"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={forFutureReasonsItems}
          value={forFutureReasonsInclude}
          disabledItems={forFutureReasonsExclude}
          onChange={makeChangeHandler('forFutureReasonsInclude')}
        />
        <MultiAutocomplete
          label="Exclude devs with For future reasons"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={forFutureReasonsItems}
          value={forFutureReasonsExclude}
          disabledItems={forFutureReasonsInclude}
          onChange={makeChangeHandler('forFutureReasonsExclude')}
        />
        <MultiAutocomplete
          label="Include self-assessed as"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={seniorityItems}
          value={selfAssessmentInclude}
          onChange={makeChangeHandler('selfAssessmentInclude')}
        />
        <Autocomplete
          label="Experience no less than"
          color="primary"
          variant="outlined"
          items={commercialExperienceItems}
          value={commercialExperience ? String(commercialExperience) : null}
          onChange={handleCommercialExperienceChange}
        />
        <ReferredByFilter value={referredById} onChange={makeChangeHandler('referredById')} />
        <CheckboxWithLabel
          sx={{ my: '-9px' }}
          label="Relevant countries only"
          value={relevantCountriesOnly}
          onChange={makeChangeHandler('relevantCountriesOnly')}
        />
        <CheckboxWithLabel
          sx={{ my: '-9px' }}
          label="Referred by someone only"
          value={referredBySomeone}
          onChange={makeChangeHandler('referredBySomeone')}
        />
      </Box>
    </Box>
  );
};

export default AdvancedFilters;
