import React, { useMemo, useState } from 'react';
import { GridDensityTypes, GridEventListener, GridRowIdGetter, GridValidRowModel } from '@mui/x-data-grid-pro';
import { Box, SxProps, Theme } from '@mui/material';

import { GridSettingsContext } from 'context';
import { Candidate } from 'types';
import { changeArrayElementOrder } from 'utils';
import { Routes } from 'config';
import BulkAssignModal from 'views/dashboard/candidates-grid/bulk-assign-modal';

import { CandidatesGridStyled, GridContainerStyled } from './candidates-grid.styled';
import GridToolbar from './grid-toolbar';
import GridFooter from './grid-footer';
import buildColDefs from './build-col-defs';
import { copy } from './copy-funciton';

interface Props {
  sx?: SxProps<Theme>;
  candidates?: Candidate[];
  candidatesAmount?: number;
  teamNamesMap: Record<string, string>;
}

const CandidatesGrid: React.FC<Props> = ({ sx, candidates = [], candidatesAmount = 0, teamNamesMap }) => {
  const {
    gridSettings,
    columnsStoredSettings,
    isInCompactView,
    columnsOrder,
    selectedIds,
    isInColDnDMode,
    storeColumnWidth,
    setSortedColumn,
    setColumnSearchQuery,
    setColumnsOrder,
    setSelectedIds,
  } = React.useContext(GridSettingsContext);
  const colDefs = buildColDefs({
    gridSettings,
    columnsSettings: columnsStoredSettings,
    isInDnDMode: isInColDnDMode,
    setSortedColumn,
    setColumnSearchQuery,
    columnsOrder,
    teamNamesMap,
  });
  const { page, perPage } = gridSettings;

  const [isBulkAssignModalOpen, setIsBulkAssignModalOpen] = useState<boolean>(false);

  /**
   * the columnsOrder doesn't contain "shortId" column, responsible for the order displayed, therefor decreasing
   * the targeIndex and oldIndex for it
   */
  const handleColOrderChange: GridEventListener<'columnOrderChange'> = ({ targetIndex, oldIndex }): void => {
    const newOrder = changeArrayElementOrder<string>(columnsOrder, targetIndex - 1, oldIndex - 1);
    setColumnsOrder(newOrder);
  };

  const handleRedirectToProfile: GridEventListener<'rowDoubleClick'> = ({ id }) => {
    window.open(`${Routes.profile}/${id}`, '_blank')?.focus();
  };

  const getRowId: GridRowIdGetter<GridValidRowModel> = (row): string => {
    if (row.id) {
      return row.id as string;
    }
    if (row._id) {
      return row.Id as string;
    }
    return row.idShort;
  };

  const rows = useMemo(
    () =>
      candidates.map((candidate, idx) => ({
        ...candidate,
        idShort: (page - 1) * perPage + idx + 1,
      })),
    [candidates, page, perPage]
  );

  const handleCopy = () => copy({ rows: rows.filter((r) => selectedIds.includes(r.id)) });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ...sx }}>
      <GridToolbar
        columnDefs={colDefs}
        onBulkAssign={() => setIsBulkAssignModalOpen(true)}
        bulkAssignDisabled={!selectedIds?.length}
        onCopy={handleCopy}
        copyDisabled={!selectedIds?.length}
      />
      <GridContainerStyled sx={{ height: '0', flex: 1 }}>
        <CandidatesGridStyled
          rows={rows}
          columns={colDefs}
          pageSize={perPage}
          density={isInCompactView ? GridDensityTypes.Compact : GridDensityTypes.Standard}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnReorder={!isInColDnDMode}
          hideFooter={true}
          onColumnWidthChange={(param) => storeColumnWidth(param.colDef.field, param.width)}
          onRowDoubleClick={handleRedirectToProfile}
          onColumnOrderChange={handleColOrderChange}
          getRowId={getRowId}
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
              backgroundColor: isInColDnDMode ? 'rgb(131, 44, 224, 0.1)' : '#F5F5F5',
            },
          }}
          checkboxSelection={true}
          keepNonExistentRowsSelected={true}
          selectionModel={selectedIds}
          onSelectionModelChange={setSelectedIds}
        />
      </GridContainerStyled>
      <GridFooter total={candidatesAmount} selected={selectedIds.length} />
      <BulkAssignModal
        isOpen={isBulkAssignModalOpen}
        candidateIds={selectedIds as string[]}
        onClose={() => setIsBulkAssignModalOpen(false)}
      />
    </Box>
  );
};

export default CandidatesGrid;
