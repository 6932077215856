import { memo, useContext } from 'react';
import { Box } from '@mui/material';

import { GridSettingsContext } from 'context';
import { ResettableSection } from 'components';
import { TagMultiAutocomplete } from 'features';

const TagsFilter = () => {
  const {
    gridSettings: { tagIds },
    setGridSettingsItem,
    resetTags,
  } = useContext(GridSettingsContext);

  return (
    <Box sx={{ px: '16px' }}>
      <ResettableSection sx={{ pr: '4px', mb: '16px' }} title="Tags filter" onReset={resetTags} />
      <TagMultiAutocomplete
        label="Tags"
        value={tagIds}
        onChange={(tagIds) => setGridSettingsItem('tagIds', tagIds)}
        dataTestId="tags-filter-autocomplete"
        variant="outlined"
      />
    </Box>
  );
};

export default memo(TagsFilter);
