import React from 'react';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';

import {
  SortOption,
  GridSettings,
  ColumnsStoredSettings,
  SortedColumnSetter,
  SortedColumn,
  ColumnSearchQuerySetter,
  ApplicationStage,
  CandidateLanguage,
} from 'types';
import { assignFirstIfDefined, formatDateFromISOString, orderColumns, getColumnVisibility } from 'utils';
import { HotSwitch } from 'components';
import { dashboardColumnsConfig } from 'config';
import { APPLICATION_STAGE_MAP } from 'constants/candidate';

import {
  AvailabilityTypeCell,
  HeaderCell,
  HottestReminderDateCell,
  RateCell,
  ClientRateCell,
  ReminderCommentCell,
  SearchHighlightCell,
  SkillsCell,
  SpecializationsCell,
  TextCell,
} from 'components';

type DateFormatterParams = {
  value: string;
};

const formatDateValue = (params: DateFormatterParams): string =>
  params.value ? formatDateFromISOString(params.value) : '';

const getColumnSortState = (fieldName: string, sortedColumn: SortedColumn): SortOption => {
  if (sortedColumn?.fieldName === fieldName) {
    return sortedColumn.sortOption!;
  }
  return SortOption.NONE;
};

type AColumnDefBuilderProps = {
  gridSettings: GridSettings;
  columnsSettings: ColumnsStoredSettings;
  setSortedColumn: SortedColumnSetter;
  setColumnSearchQuery: ColumnSearchQuerySetter;
  isInDnDMode: boolean;
};

type ColumnsDefBuilderProps = AColumnDefBuilderProps & {
  columnsOrder: string[];
  teamNamesMap: Record<string, string>;
};

type AColumnCustoms = [
  fieldName: string,
  headerName: string,
  isSearchable: boolean,
  isSortable: boolean,
  defaultWidth: number,
  sortByDescFirst: boolean
];

type ColPartDef = {
  headerName: string;
  width: number;
  hide: boolean;
  renderHeader: () => JSX.Element;
};

const buildColPartDef = (
  {
    gridSettings: { sortedColumn, columnSearches },
    columnsSettings,
    setSortedColumn,
    setColumnSearchQuery,
    isInDnDMode,
  }: AColumnDefBuilderProps,
  ...args: AColumnCustoms
): ColPartDef => {
  const [fieldName, headerName, isSearchable, isSortable, defaultWidth, sortByDescFirst] = args;
  return {
    headerName,
    width: assignFirstIfDefined(columnsSettings?.[fieldName]?.width, defaultWidth),
    hide: !getColumnVisibility(fieldName, dashboardColumnsConfig, columnsSettings), //assignFirstIfDefined(columnsSettings?.[fieldName]?.isVisible, !isVisible),
    renderHeader: () => (
      <HeaderCell
        title={headerName}
        sortable={isSortable}
        searchable={isSearchable}
        sortState={getColumnSortState(fieldName, sortedColumn)}
        fieldName={fieldName}
        onSortChange={setSortedColumn}
        search={columnSearches[fieldName]}
        onSearchChange={setColumnSearchQuery}
        isInDnDMode={isInDnDMode}
        sortByDescFirst={sortByDescFirst ?? false}
      />
    ),
  };
};

const buildColDefs = (args: ColumnsDefBuilderProps): GridEnrichedColDef[] => {
  const { gridSettings, columnsSettings, columnsOrder, teamNamesMap } = args;

  const initialColDefs: GridEnrichedColDef[] = [
    {
      field: 'name',
      ...buildColPartDef(args, 'name', 'Name', true, true, 150, false),
      renderCell: ({ row }) => (
        <SearchHighlightCell
          searchValue={gridSettings.columnSearches.name}
          fieldValue={`${row.firstName} ${row.lastName}`}
        />
      ),
      sortable: false,
    },
    {
      field: 'isHot',
      ...buildColPartDef(args, 'isHot', 'Hot', false, true, 80, true),
      align: 'left',
      sortable: false,
      renderCell: (params) => <HotSwitch candidateId={params.row.id} isHot={params.row.isHot} size="small" />,
    },
    {
      field: 'email',
      ...buildColPartDef(args, 'email', 'Email', true, true, 250, false),
      renderCell: ({ value }) => (
        <SearchHighlightCell searchValue={gridSettings.columnSearches.email} fieldValue={value} />
      ),
      sortable: false,
    },
    {
      field: 'created_date',
      ...buildColPartDef(args, 'created_date', 'Created', false, true, 110, false),
      headerName: 'Created',
      valueFormatter: formatDateValue,
      sortable: false,
      align: 'right',
    },
    {
      field: 'applicationStage',
      ...buildColPartDef(args, 'applicationStage', 'Application stage', true, true, 170, false),
      renderCell: ({ value }) => (
        <SearchHighlightCell
          searchValue={gridSettings.columnSearches.applicationStage}
          fieldValue={APPLICATION_STAGE_MAP[value as ApplicationStage]}
        />
      ),
      sortable: false,
    },
    {
      field: 'availability_type',
      ...buildColPartDef(args, 'availability_type', 'Availability type', false, true, 150, false),
      renderCell: ({ row }) => <AvailabilityTypeCell availabilityType={row.availability_type} />,
      sortable: false,
    },
    {
      field: 'avEngHours',
      ...buildColPartDef(args, 'avEngHours', 'Avail./Eng. hours', false, true, 170, false),
      sortable: false,
      align: 'left',
      renderCell: ({ row }) => {
        const availabilityHoursPerWeek = row.available_hours_per_week;
        const totalHoursEngaged = row.total_hours_engaged;
        if (totalHoursEngaged) {
          return `${availabilityHoursPerWeek} / ${totalHoursEngaged}`;
        }
        return availabilityHoursPerWeek;
      },
    },
    {
      field: 'rate_per_hour',
      ...buildColPartDef(args, 'rate_per_hour', 'Rate', false, true, 150, false),
      align: 'right',
      sortable: false,
      renderCell: (params) => <RateCell candidate={params.row} />,
    },
    {
      field: 'client_rate_per_hour',
      ...buildColPartDef(args, 'client_rate_per_hour', 'Client rate', false, true, 150, false),
      align: 'right',
      sortable: false,
      renderCell: (params) => <ClientRateCell clientRate={params.row.client_rate_per_hour} />,
    },
    {
      field: 'oldestReminderTimestamp',
      ...buildColPartDef(args, 'oldestReminderTimestamp', 'Hottest reminder', false, true, 170, false),
      align: 'left',
      sortable: false,
      renderCell: (params) => <HottestReminderDateCell timestamp={params.row.oldestReminderTimestamp} />,
    },
    {
      field: 'oldestReminderComment',
      ...buildColPartDef(args, 'oldestReminderComment', 'Reminder comment', false, false, 170, false),
      align: 'left',
      sortable: false,
      renderCell: (params) =>
        params.row?.oldestReminderComment ? <ReminderCommentCell comment={params.row.oldestReminderComment} /> : null,
    },
    {
      field: 'role',
      ...buildColPartDef(args, 'role', 'Role', false, true, 150, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'team',
      ...buildColPartDef(args, 'team', 'Team', false, true, 150, false),
      align: 'left',
      sortable: false,
      renderCell: ({ row }) => (row.teamId ? <TextCell>{teamNamesMap[row.teamId]}</TextCell> : null),
    },
    {
      field: 'payoneer_name',
      ...buildColPartDef(args, 'payoneer_name', 'Payoneer name', false, true, 150, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'w8ben_link',
      ...buildColPartDef(args, 'w8ben_link', 'w8ben URL', false, true, 130, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'specializations',
      ...buildColPartDef(args, 'specializations', 'Specialization', true, false, 210, false),
      sortable: false,
      renderCell: ({ value }) => (
        <SpecializationsCell specializations={value} match={gridSettings.columnSearches.specializations} />
      ),
    },
    {
      field: 'level',
      ...buildColPartDef(args, 'level', 'Level', false, false, 150, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'yearsOfExperience',
      ...buildColPartDef(args, 'yearsOfExperience', 'Experience', false, true, 230, true),
      align: 'right',
      sortable: false,
    },
    {
      field: 'link_to_cv',
      ...buildColPartDef(args, 'link_to_cv', 'Link to CV', false, true, 230, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'linkedin',
      ...buildColPartDef(args, 'linkedin', 'LinkedIn', false, true, 230, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'status_in_system',
      ...buildColPartDef(args, 'status_in_system', 'Status in system', false, true, 170, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'status_change_date',
      ...buildColPartDef(args, 'status_change_date', 'Status change date', false, true, 170, true),
      valueFormatter: formatDateValue,
      align: 'right',
      sortable: false,
    },
    {
      field: 'last_change_date',
      ...buildColPartDef(args, 'last_change_date', 'Last changed date', false, true, 170, true),
      valueFormatter: formatDateValue,
      align: 'right',
      sortable: false,
    },
    {
      field: 'last_note_date',
      ...buildColPartDef(args, 'last_note_date', 'Last note date', false, true, 170, true),
      valueFormatter: formatDateValue,
      align: 'right',
      sortable: false,
    },
    {
      field: 'last_note',
      ...buildColPartDef(args, 'last_note', 'Last note', true, true, 170, false),
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <SearchHighlightCell
          searchValue={(gridSettings.columnSearches.last_note ?? '').replace(',', ' ')}
          fieldValue={value}
        />
      ),
    },
    {
      field: 'mainSkills',
      ...buildColPartDef(args, 'mainSkills', 'Main Skills', true, false, 250, false),
      sortable: false,
      renderCell: ({ value }) => <SkillsCell skills={value} match={gridSettings.columnSearches.mainSkills} />,
    },
    {
      field: 'otherSkills',
      ...buildColPartDef(args, 'otherSkills', 'Other Skills', true, false, 250, false),
      sortable: false,
      renderCell: ({ value }) => <SkillsCell skills={value} match={gridSettings.columnSearches.otherSkills} />,
    },
    {
      field: 'tagNames',
      ...buildColPartDef(args, 'tagNames', 'Tags', true, false, 170, false),
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <SearchHighlightCell searchValue={gridSettings.columnSearches.tagNames} fieldValue={(value ?? []).join(', ')} />
      ),
    },
    {
      field: 'country',
      ...buildColPartDef(args, 'country', 'Country', true, true, 135, false),
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <SearchHighlightCell
          searchValue={(gridSettings.columnSearches.country ?? '').replace(',', ' ')}
          fieldValue={value}
        />
      ),
    },
    {
      field: 'languages',
      ...buildColPartDef(args, 'languages', 'Additional languages', true, true, 250, false),
      align: 'left',
      sortable: false,
      renderCell: ({ value }) => (
        <SearchHighlightCell
          searchValue={(gridSettings.columnSearches.languages ?? '')
            .split(' ')
            .map((str) => str.split('@')[0])
            .join(' ')}
          fieldValue={(value as Array<CandidateLanguage>).map((v) => v.language).join(', ')}
        />
      ),
    },
    {
      field: 'daysInTheCommunity',
      ...buildColPartDef(args, 'daysInTheCommunity', 'Days in community', false, true, 175, false),
      align: 'right',
      sortable: false,
    },
    {
      field: 'projectsOfferedCount',
      ...buildColPartDef(args, 'projectsOfferedCount', 'Projects offered', false, true, 155, false),
      align: 'right',
      sortable: false,
    },
    {
      field: 'weakCandidateDeclinesCount',
      ...buildColPartDef(args, 'weakCandidateDeclinesCount', 'Weak candidate', false, true, 155, false),
      align: 'right',
      sortable: false,
    },
    {
      field: 'activatedSubscriptionsCount',
      ...buildColPartDef(args, 'activatedSubscriptionsCount', 'Subs over time', false, true, 150, false),
      align: 'right',
      sortable: false,
    },
    {
      field: 'nonOrganicChurnsCount',
      ...buildColPartDef(args, 'nonOrganicChurnsCount', 'Non-organic churns', false, true, 180, false),
      align: 'right',
      sortable: false,
    },
    {
      field: 'city',
      ...buildColPartDef(args, 'city', 'City', false, true, 135, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'telegram',
      ...buildColPartDef(args, 'telegram', 'Telegram', true, true, 140, false),
      align: 'left',
      sortable: false,
    },
    {
      field: 'phone',
      ...buildColPartDef(args, 'phone', 'Phone', true, true, 150, false),
      renderCell: ({ value }) => (
        <SearchHighlightCell searchValue={gridSettings.columnSearches.phone} fieldValue={value} />
      ),
      sortable: false,
    },
  ];

  return [
    {
      field: 'idShort',
      headerName: '#',
      width: 70,
      hide: assignFirstIfDefined(columnsSettings?.idShort?.isVisible, false),
      align: 'left',
      sortable: false,
      resizable: false,
      disableReorder: true,
    },
    ...orderColumns(initialColDefs, columnsOrder),
  ];
};
export default buildColDefs;
