import { IconButton, Tooltip, Box, Checkbox } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';

export const MENU_ITEM_WIDTH = 276;
export const MENU_CHUNK_GAP = 32;
export const BUTTON_ID = 'columns-settings-btn';
export const MENU_ID = 'columns-settings-menu';

interface SettingsButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const SettingsButton: React.FC<SettingsButtonProps> = ({ onClick }) => (
  <IconButton aria-label="upload picture" component="span" size="small" onClick={onClick} color="primary">
    <Tooltip title="Columns visibility">
      <SettingsIcon />
    </Tooltip>
  </IconButton>
);

interface SelectableColumnItemProps {
  colDef: GridEnrichedColDef;
  onClick: () => void;
}

export const SelectableColumnItem: React.FC<SelectableColumnItemProps> = ({ colDef, onClick }) => (
  <Box
    sx={{
      flex: 1,
      m: '2px',
      cursor: 'pointer',
      '&&: hover': {
        backgroundColor: (theme) => theme.palette.lightBackground.primary,
      },
      ...(colDef && !colDef.hide
        ? {
            backgroundColor: (theme) => theme.palette.lightBackground.primary,
          }
        : {}),
      width: `${MENU_ITEM_WIDTH}px`,
    }}
    onClick={onClick}
  >
    {colDef ? (
      <>
        <Checkbox size="small" checked={!colDef.hide} color="primary" /> {colDef.headerName}
      </>
    ) : null}
  </Box>
);
