import React from 'react';
import { SxProps, Menu, Box } from '@mui/material';
import { GridEnrichedColDef } from '@mui/x-data-grid-pro';
import { chunkArray, isLastIdxInArr } from 'utils';
import {
  SettingsButton,
  MENU_ITEM_WIDTH,
  MENU_ID,
  BUTTON_ID,
  SelectableColumnItem,
  MENU_CHUNK_GAP,
} from './columns-visibility-settings.styled';
import { FlexColumn, FlexRow } from 'components';

interface Props {
  sx?: SxProps;
  columnDefs: GridEnrichedColDef[];
  onItemChange: (colField: string, status: boolean) => void;
  deckSize?: number;
}

const ColumnsVisibilitySettings: React.FC<Props> = ({ sx = {}, columnDefs, deckSize = 9, onItemChange }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const columnDefsChunks: GridEnrichedColDef[][] = chunkArray<GridEnrichedColDef>(columnDefs, deckSize);
  const menuItemWidth = React.useMemo(
    () => `${MENU_ITEM_WIDTH * columnDefsChunks.length + MENU_CHUNK_GAP * (columnDefsChunks.length - 1) + 8}px`,
    [columnDefsChunks]
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColVisibilityChange = (headerName: string): void => {
    const changedColumn = columnDefs.find((colDef) => colDef.headerName === headerName);
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    onItemChange(changedColumn?.field!, !changedColumn?.hide!);
  };

  return (
    <Box sx={sx}>
      <SettingsButton onClick={handleClick} />
      <Menu
        id={MENU_ID}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': BUTTON_ID,
        }}
      >
        <FlexRow sx={{ width: menuItemWidth, px: '4px' }}>
          {[...Array(columnDefsChunks.length)].map((_, colIdx) => (
            <FlexColumn
              key={`gcs-menu-col-${colIdx}`}
              sx={{
                alignSelf: 'flex-start',
                ...(isLastIdxInArr(columnDefsChunks, colIdx) ? {} : { mr: `${MENU_CHUNK_GAP}px` }),
              }}
            >
              {columnDefsChunks[colIdx].map((colDef: GridEnrichedColDef) => (
                <SelectableColumnItem
                  key={`gcs-box-item-${colDef.field}`}
                  colDef={colDef}
                  onClick={() => handleColVisibilityChange(colDef!.headerName!)}
                />
              ))}
            </FlexColumn>
          ))}
        </FlexRow>
      </Menu>
    </Box>
  );
};

export default ColumnsVisibilitySettings;
