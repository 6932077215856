import { useContext, useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';

import { GridSettingsContext } from 'context';
import { RadioButtonGroup, ResettableSection, MultiAutocomplete } from 'components';
import { LeadTypeOptionValue, SelectItem } from 'types';

import { useSettingsChangeHandler, useSourcesItems } from './utils';
import FiltersBySourceModal from './filters-by-sources-modal';

const FiltersBySources = () => {
  const { gridSettings, resetFiltersBySources } = useContext(GridSettingsContext);

  const { leadTypeSource, websiteSources, adTypeSources, campaignSources } = gridSettings;

  const { sourcesItems } = useSourcesItems({
    gridSettings,
  });

  const [isExtendedView, setIsExtendedView] = useState(false);

  const openExtendedView = () => setIsExtendedView(true);
  const closeExtendedView = () => setIsExtendedView(false);

  const { makeChangeHandler } = useSettingsChangeHandler();

  const leadTypeItems = useMemo<Array<SelectItem<string>>>(
    () => [
      { value: LeadTypeOptionValue.ALL_LEADS, name: 'All leads' },
      { value: LeadTypeOptionValue.INBOUND, name: 'Inbound' },
      { value: LeadTypeOptionValue.OUTREACHED, name: 'Outreached' },
    ],
    []
  );

  return (
    <Box sx={{ px: '16px' }}>
      <ResettableSection sx={{ pr: '4px', mb: '8px' }} title="Filters by sources" onReset={resetFiltersBySources} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <RadioButtonGroup
          buttons={leadTypeItems}
          initialSelection={leadTypeSource}
          onChange={makeChangeHandler('leadTypeSource')}
        />
        <MultiAutocomplete
          label="Website"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={sourcesItems.sourceL2}
          value={websiteSources}
          onChange={makeChangeHandler('websiteSources')}
        />
        <MultiAutocomplete
          label="Ad type (medium or ref)"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={sourcesItems.sourceL3}
          value={adTypeSources}
          onChange={makeChangeHandler('adTypeSources')}
        />
        <MultiAutocomplete
          label="Campaign name"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={sourcesItems.sourceL4}
          value={campaignSources}
          onChange={makeChangeHandler('campaignSources')}
        />
        <Button
          sx={{ alignSelf: 'flex-start' }}
          color="primary"
          endIcon={<PhotoSizeSelectSmallIcon />}
          onClick={openExtendedView}
        >
          Extended view
        </Button>
      </Box>
      <FiltersBySourceModal isOpen={isExtendedView} onClose={closeExtendedView} />
    </Box>
  );
};

export default FiltersBySources;
