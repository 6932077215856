import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';

import { GridSettingsProvider } from 'context';
import { Routes } from 'config';
import { Loader } from 'components';
import { useAuth } from 'hooks';
import DashboardView from 'views/dashboard';

import ErrorBoundary from './error-boundary';

const Dashboard = () => {
  const isAuthenticated = useAuth([Routes.dashboard]);

  return (
    <>
      <Head>
        <title>Dashboard - Holy Place</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {isAuthenticated ? (
        <ErrorBoundary>
          <GridSettingsProvider shouldConfirmSettingsChange={true} gridEntityName="candidate">
            <DashboardView />
          </GridSettingsProvider>
        </ErrorBoundary>
      ) : (
        <Loader open={true} />
      )}
    </>
  );
};

export default dynamic(() => Promise.resolve(Dashboard), { ssr: false });
