import React, { memo, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z as zod } from 'zod';
import { useSnackbar } from 'notistack';

import { Modal, Autocomplete } from 'components';
import { Content, Title, Actions } from 'components/modal-form';
import { getError } from 'utils';
import { TagGroup, CampaignTagCategory } from 'types';
import { useBulkAssignToContacts, useOperatorsFetch } from 'api';
import { TagAutocomplete } from 'features';

const schema = zod
  .object({
    operatorId: zod.string().nullable().optional(),
    tagId: zod.string().nullable().optional(),
  })
  .partial()
  .refine((data) => data.operatorId || data.tagId, 'Either operator or tag should be selected.');

interface AddTagFormValues {
  operatorId?: string;
  tagId?: string;
}

interface BulkAssignModalProps {
  isOpen: boolean;
  onClose: () => void;
  candidateIds: string[];
}

const BulkAssignModal = ({ isOpen, onClose, candidateIds }: BulkAssignModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: operators } = useOperatorsFetch();

  const { bulkAssignToContacts, bulkAssignToContactsLoading } = useBulkAssignToContacts();

  const defaultValues = useMemo<AddTagFormValues>(
    () => ({
      operatorId: undefined,
      tagId: '',
    }),
    []
  );

  const operatorItems = React.useMemo(
    () =>
      operators
        ? [...operators]
            .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
            .map((operator) => ({ name: operator.name, value: operator.id }))
        : [],
    [operators]
  );
  const operatorDisabledItems = useMemo(
    () =>
      operators
        ? [...operators].filter((operator) => operator.email.includes('deleted')).map((operator) => operator.id)
        : [],
    [operators]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<AddTagFormValues>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const submit = handleSubmit((data) => {
    bulkAssignToContacts(
      { contactIds: candidateIds, tagId: data.tagId, userIdToAssignAsDedicatedOperator: data.operatorId },
      {
        onSuccess: () => {
          enqueueSnackbar(
            `${
              data.tagId && data.operatorId ? 'Tag and operator' : data.tagId ? 'Tag' : 'Operator'
            } assigned to contacts`,
            { variant: 'success' }
          );
          onClose();
        },
        onError: (error) => {
          const { message } = error as Error;
          enqueueSnackbar(message || 'Failed to bulk assign tag', { variant: 'error' });
        },
      }
    );
  });

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen}>
      <Content>
        <Title sx={{ mb: '24px' }}>Bulk assign</Title>
        <Controller
          name="operatorId"
          control={control}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              sx={{ marginBottom: '8px' }}
              error={!!getError(fieldState)}
              label="Assigned operator"
              items={operatorItems}
              disabledItems={operatorDisabledItems}
            />
          )}
        />
        <Controller
          name="tagId"
          control={control}
          render={({ field, fieldState }) => (
            <TagAutocomplete
              {...field}
              sx={{ marginBottom: '12px' }}
              error={!!getError(fieldState)}
              label="Campaign name"
              categories={[CampaignTagCategory.Reactivation]}
              groups={[TagGroup.Campaign]}
              sorting={{ createdDate: 'DESC' }}
            />
          )}
        />
        <span>
          You are about to bulk-update <span style={{ fontWeight: 'bold' }}>{candidateIds.length}</span> profiles. Sure
          you want to proceed?
        </span>
      </Content>
      <Actions
        submitLabel="Bulk assign"
        onCancel={onClose}
        onSubmit={submit}
        disabled={!isValid || bulkAssignToContactsLoading}
      />
    </Modal>
  );
};

export default memo(BulkAssignModal);
