import { useState, useContext, useEffect, useMemo } from 'react';
import { SplitPane } from 'react-collapse-pane';
import { useTheme, Button, Box } from '@mui/material';

import { Modal, UFormInput, CheckboxList, RadioButtonGroup } from 'components';
import { SelectItem, LeadTypeOptionValue } from 'types';
import { GridSettingsContext } from 'context';
import { useDebounce } from 'hooks';

import { useSourcesItems } from './utils';
import {
  Header,
  TitleMainText,
  ButtonsSection,
  MainContent,
  FilterColumnsContainer,
  FilterColumn,
  FilterTitle,
  CheckboxListWrapper,
} from './filters-by-sources-modal.styled';

interface FiltersBySourceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FiltersBySourceModal = ({ isOpen, onClose }: FiltersBySourceModalProps) => {
  const { gridSettings, setGridSettings } = useContext(GridSettingsContext);

  const { leadTypeSource, websiteSources, adTypeSources, campaignSources, customSources } = gridSettings;

  const theme = useTheme();

  const leadTypeItems = useMemo<Array<SelectItem<string>>>(
    () => [
      { value: LeadTypeOptionValue.ALL_LEADS, name: 'All leads' },
      { value: LeadTypeOptionValue.INBOUND, name: 'Inbound' },
      { value: LeadTypeOptionValue.OUTREACHED, name: 'Outreached' },
    ],
    []
  );

  const [websiteSearch, setWebsiteSearch] = useState<string>('');
  const [adTypeSearch, setAdTypeSearch] = useState<string>('');
  const [campaignNameSearch, setCampaignNameSearch] = useState<string>('');
  const [customSourceSearch, setCustomSourceSearch] = useState<string>('');

  const [localLeadTypeSource, setLocalLeadTypeSource] = useState(leadTypeSource);
  const [localWebsiteSources, setLocalWebsiteSources] = useState<Array<string>>(websiteSources);
  const [localAdTypeSources, setLocalAdTypeSources] = useState<Array<string>>(adTypeSources);
  const [localCampaignSources, setLocalCampaignSources] = useState<Array<string>>(campaignSources);
  const [localCustomSources, setLocalCustomSources] = useState<Array<string>>(customSources);

  const { sourcesItems } = useSourcesItems({
    gridSettings,
    sources: {
      sourceL2: localWebsiteSources,
      sourceL3: localAdTypeSources,
      sourceL4: localCampaignSources,
      sourceL5: localCustomSources,
    },
  });

  const handleWebsiteSearchChange = useDebounce((search: string) => setWebsiteSearch(search), 500);
  const handleAdTypeSearchChange = useDebounce((search: string) => setAdTypeSearch(search), 500);
  const handleCampaignNameSearchChange = useDebounce((search: string) => setCampaignNameSearch(search), 500);
  const handleCustomSourceSearchChange = useDebounce((search: string) => setCustomSourceSearch(search), 500);

  const resetLocalValues = () => {
    setLocalLeadTypeSource(leadTypeSource);
    setLocalWebsiteSources(websiteSources);
    setLocalAdTypeSources(adTypeSources);
    setLocalCampaignSources(campaignSources);
    setLocalCustomSources(customSources);
  };

  const handleCancel = () => {
    resetLocalValues();
    onClose();
  };

  const handleSubmit = () => {
    setGridSettings((gridSettings) => ({
      ...gridSettings,
      leadTypeSource: localLeadTypeSource,
      websiteSources: localWebsiteSources,
      adTypeSources: localAdTypeSources,
      campaignSources: localCampaignSources,
      customSources: localCustomSources,
    }));

    onClose();
  };

  useEffect(() => {
    resetLocalValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal sx={{ width: 'auto', maxWidth: 'calc(100vw - 64px)' }} isOpen={isOpen}>
      <Header>
        <TitleMainText>Filters by sources</TitleMainText> Extended view
      </Header>
      <MainContent>
        <Box sx={{ width: '332px', padding: '0 16px', marginBottom: '12px' }}>
          <RadioButtonGroup
            buttons={leadTypeItems}
            initialSelection={localLeadTypeSource}
            onChange={setLocalLeadTypeSource}
          />
        </Box>
        <FilterColumnsContainer>
          <SplitPane
            split="vertical"
            resizerOptions={{
              css: {
                width: '1px',
                background: '#EEEEEE',
              },
              hoverCss: {
                width: '2px',
                background: theme.palette.primary.main,
              },
            }}
          >
            <FilterColumn>
              <FilterTitle>Website</FilterTitle>
              <UFormInput
                sx={{ mb: '16px' }}
                label="Search"
                initialValue={websiteSearch}
                onChange={handleWebsiteSearchChange}
              />
              <CheckboxListWrapper>
                <CheckboxList
                  items={sourcesItems.sourceL2}
                  values={localWebsiteSources}
                  onChange={setLocalWebsiteSources}
                  search={websiteSearch}
                />
              </CheckboxListWrapper>
            </FilterColumn>
            <FilterColumn>
              <FilterTitle>Ad type</FilterTitle>
              <UFormInput
                sx={{ mb: '16px' }}
                label="Search"
                initialValue={adTypeSearch}
                onChange={handleAdTypeSearchChange}
              />
              <CheckboxListWrapper>
                <CheckboxList
                  items={sourcesItems.sourceL3}
                  values={localAdTypeSources}
                  onChange={setLocalAdTypeSources}
                  search={adTypeSearch}
                />
              </CheckboxListWrapper>
            </FilterColumn>
            <FilterColumn>
              <FilterTitle>Campaign name</FilterTitle>
              <UFormInput
                sx={{ mb: '16px' }}
                label="Search"
                initialValue={campaignNameSearch}
                onChange={handleCampaignNameSearchChange}
              />
              <CheckboxListWrapper>
                <CheckboxList
                  items={sourcesItems.sourceL4}
                  values={localCampaignSources}
                  onChange={setLocalCampaignSources}
                  search={campaignNameSearch}
                />
              </CheckboxListWrapper>
            </FilterColumn>
            <FilterColumn>
              <FilterTitle>Custom source</FilterTitle>
              <UFormInput
                sx={{ mb: '16px' }}
                label="Search"
                initialValue={customSourceSearch}
                onChange={handleCustomSourceSearchChange}
              />
              <CheckboxListWrapper>
                <CheckboxList
                  items={sourcesItems.sourceL5}
                  values={localCustomSources}
                  onChange={setLocalCustomSources}
                  search={customSourceSearch}
                />
              </CheckboxListWrapper>
            </FilterColumn>
          </SplitPane>
        </FilterColumnsContainer>
      </MainContent>
      <ButtonsSection>
        <Button onClick={handleCancel} sx={{ py: '8px', width: '122px' }} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} sx={{ py: '8px', width: '122px' }} variant="contained" color="primary">
          Apply filter
        </Button>
      </ButtonsSection>
    </Modal>
  );
};

export default FiltersBySourceModal;
