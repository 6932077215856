import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StorageIcon from '@mui/icons-material/Storage';

export const ToolbarContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'white',
}));

export const SearchWrapper = styled(Box)(() => ({
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '16px',
  flexGrow: 1,
}));

interface ResetStorageButtonProps {
  onClick?: () => void;
}

export const ResetStorageButton: React.FC<ResetStorageButtonProps> = ({ onClick = () => {} }) => (
  <IconButton
    onClick={onClick}
    aria-label="upload picture"
    component="span"
    size="small"
    color="primary"
    sx={{ marginRight: '8px' }}
  >
    <Tooltip title="Reset table settings">
      <StorageIcon sx={{ zIndex: 1 }} />
    </Tooltip>
    <HighlightOffIcon
      sx={{
        fontSize: '18px',
        marginTop: '-16px',
        marginLeft: '-12px',
        zIndex: 12,
        padding: '1px',
        borderRadius: '7px',
        backgroundColor: 'white',
      }}
    />
  </IconButton>
);
