import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { StatusesList, ResettableSection, SelectableItem, CheckboxState } from 'components';
import { ContactStatuses, CONTACT_STATUSES } from 'config';
import { assignFirstIfDefined } from 'utils';
import { StatusCounters } from 'types';
import { GridSettingsContext } from 'context';

const buildSelectableStatusesArr = (
  allStatuses: ContactStatuses[],
  selectedStatuses: ContactStatuses[],
  statusCounters: StatusCounters | undefined
): SelectableItem[] =>
  allStatuses.map((status) => ({
    title: status,
    count: assignFirstIfDefined<number>((statusCounters || {})[status], 0),
    selected: selectedStatuses.includes(status),
  }));

interface Props {
  statuses: ContactStatuses[];
  onChange: (items: SelectableItem[]) => void;
  statusCounters?: StatusCounters;
}

const StatusesFilter: React.FC<Props> = ({ statuses, onChange, statusCounters }) => {
  const { resetStatuses } = useContext(GridSettingsContext);

  const [candidateStatuses, setCandidateStatuses] = React.useState<SelectableItem[]>(
    buildSelectableStatusesArr(CONTACT_STATUSES, statuses, statusCounters)
  );

  React.useEffect(() => {
    setCandidateStatuses(buildSelectableStatusesArr(CONTACT_STATUSES, statuses, statusCounters));
  }, [statuses, statusCounters]);

  const calcCheckboxState = (): CheckboxState => {
    if (candidateStatuses.every((candidateStatus) => candidateStatus.selected)) {
      return CheckboxState.CHECKED;
    }
    if (candidateStatuses.every((candidateStatus) => !candidateStatus.selected)) {
      return CheckboxState.UNCHECKED;
    }
    return CheckboxState.INDETERMINATE;
  };

  const handleAllItemsSelectedUpdate = (value: boolean): void => {
    const updatedItems = candidateStatuses.map((candidateStatus) => ({ ...candidateStatus, selected: value }));
    onChange(updatedItems);
  };

  return (
    <Box sx={{ px: '8px' }}>
      <ResettableSection
        title="Statuses"
        sx={{ pr: '12px' }}
        onSelectAll={() => handleAllItemsSelectedUpdate(true)}
        onDeselectAll={() => handleAllItemsSelectedUpdate(false)}
        checkBoxState={calcCheckboxState()}
        onReset={resetStatuses}
      />
      <StatusesList items={candidateStatuses} sx={{ pl: '16px' }} onChange={onChange} />
    </Box>
  );
};

export default StatusesFilter;
