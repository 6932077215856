import React from 'react';
import { Box, Typography, Theme } from '@mui/material';

interface FooterLabelProps {
  value: number;
  label: 'Found' | 'Selected';
  isSmallScreen?: boolean;
}
export const FooterLabel: React.FC<FooterLabelProps> = ({ value, label, isSmallScreen = false }) => (
  <Box
    sx={{
      border: (theme: Theme) => (isSmallScreen ? 'none' : `1px solid ${theme.palette.primary.main}`),
      borderRadius: '12px',
      px: isSmallScreen ? '4px' : '12px',
      mr: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    }}
  >
    <Typography
      sx={{
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '2px',
      }}
      color="primary"
    >
      {isSmallScreen ? value : `${label} ${value}`}
    </Typography>
  </Box>
);
