import { styled, Box } from '@mui/material';

export const Header = styled(Box)(() => ({
  padding: '8px 16px',
  backgroundColor: '#FAFAFA',
}));

export const TitleMainText = styled(Box)(() => ({
  fontWeight: '700',
}));

TitleMainText.defaultProps = { component: 'span' };

export const ButtonsSection = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end',
  padding: '8px 16px',
  backgroundColor: '#FAFAFA',
}));

export const MainContent = styled(Box)(() => ({
  paddingTop: '12px',
  paddingBottom: '8px',
}));

export const FilterColumnsContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  width: '90vw',
  maxHeight: '1250px',
  height: '500px',
}));

export const FilterColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  height: '500px',
  padding: '0 16px',
}));

export const FilterTitle = styled(Box)(() => ({
  fontWeight: '700',
  marginBottom: '4px',
}));

export const CheckboxListWrapper = styled(Box)(() => ({
  height: '0',
  flexGrow: 1,
  overflowY: 'auto',
}));
