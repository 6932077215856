import { Candidate } from 'types/domain';

interface CopyParams {
  rows: Candidate[];
}

export const copy = ({ rows }: CopyParams) => {
  const td = ({ value, bold }: { value: string; bold: boolean }) => {
    return `<td style="overflow:hidden;padding:2px 3px 2px 3px;vertical-align:bottom;${
      bold ? 'font-weight:bold;' : ''
    }" 
    data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;${value}&quot;}">${value}</td>`;
  };

  const tableRows = rows.map((row) => {
    const formattedSpecializations = (row.specializations || [])
      .map((specialization) => {
        const specializationSeniority = specialization.seniority ? specialization.seniority.trim() + ' ' : '';
        const specializationTitle = specialization.title ? specialization.title.trim() : specialization.title;

        return specializationSeniority + specializationTitle;
      })
      .filter((specialization: string) => specialization !== '');

    const specializationsAsStrings = formattedSpecializations.join(' / ');

    return `<tr style="height:21px;">
      ${td({ value: `${row.firstName} ${row.lastName}`, bold: false })}
      ${td({ value: specializationsAsStrings, bold: false })}
      ${td({ value: row.country ?? '', bold: false })}
      ${td({ value: row.email, bold: false })}
      ${td({ value: row.linkedin ?? '', bold: false })}
    </tr>`;
  });

  const html = `<meta charset='utf-8'><google-sheets-html-origin>
  <style type="text/css">
    <!--td {border: 1px solid #cccccc;}br {mso-data-placement:same-cell;}
    -->
  </style>
  <table xmlns="http://www.w3.org/1999/xhtml" cellspacing="0" cellpadding="0" dir="ltr" border="1"
      style="table-layout:fixed;font-size:10pt;font-family:Arial;width:0px;border-collapse:collapse;border:none"
      data-sheets-root="1">
      <colgroup>
        <col width="100" />
        <col width="200" />
        <col width="200" />
        <col width="200" />
        <col width="200" />
      </colgroup>
      <tbody>
  <tr style="height:21px;">
    ${td({ value: 'Name', bold: true })}
    ${td({ value: 'Specialization', bold: true })}
    ${td({ value: 'Country', bold: true })}
    ${td({ value: 'Email', bold: true })}
    ${td({ value: 'LinkedIn', bold: true })}
  </tr>
  ${tableRows.join('')}
      </tbody>
    </table>`;

  const convertHtmlToPlain = (html: string) => {
    const root = document.createElement('div');
    root.innerHTML = html;
    return root.textContent || root.innerText || '';
  };

  const clipboardItem = new ClipboardItem({
    'text/plain': new Blob([convertHtmlToPlain(html)], { type: 'text/plain' }),
    'text/html': new Blob([html], { type: 'text/html' }),
  });

  navigator.clipboard.write([clipboardItem]);
};
