import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/material';

import { RadioButtonGroup, ResettableSection, Autocomplete, MultiAutocomplete } from 'components';
import { CONTACT_AVAILABILITY_TYPES, CONTACT_AVAILABILITY_HOURS, ContactRoles, CONTACT_ROLES_ITEMS } from 'config';
import { Operator, MinifiedTeam } from 'types';
import { GridSettingsContext } from 'context';
import { makeSelectItems } from 'utils/app';

interface Props {
  operators?: Operator[];
  teams?: MinifiedTeam[];
}

const GeneralFilters: React.FC<Props> = ({ operators = [], teams = [] }) => {
  const {
    gridSettings: { role, availabilityType, availabilityHours, operatorId, dedicatedOperatorId, team },
    setGridSettingsItem,
    resetGeneralFilters,
    setRole,
  } = useContext(GridSettingsContext);

  const operatorItems = useMemo(
    () =>
      [...operators]
        .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
        .map((operator) => ({ name: operator.name, value: operator.name })),
    [operators]
  );
  const operatorDisabledItems = useMemo(
    () => [...operators].filter((operator) => operator.email.includes('deleted')).map((operator) => operator.name),
    [operators]
  );

  const handleRoleChange = (selectedRole: string) => {
    setRole(selectedRole as ContactRoles);
  };

  const handleOperatorChange = (value: string | null): void => {
    let selectedOperatorId = null;
    if (value) {
      const selectedOperator = operators.find((operator) => operator.name === value) || null;
      if (selectedOperator) {
        selectedOperatorId = selectedOperator.id;
      }
    }
    setGridSettingsItem('operatorId', selectedOperatorId);
  };

  const handleDedicatedOperatorChange = (value: string | null): void => {
    let selectedDedicatedOperatorId = null;
    if (value === 'none') {
      selectedDedicatedOperatorId = 'none';
    } else if (value) {
      const selectedDedicatedOperator = operators.find((operator) => operator.name === value) || null;
      if (selectedDedicatedOperator) {
        selectedDedicatedOperatorId = selectedDedicatedOperator.id;
      }
    }
    setGridSettingsItem('dedicatedOperatorId', selectedDedicatedOperatorId);
  };

  const handleTeamChange = (value: string | null): void => {
    setGridSettingsItem('team', value);
  };

  const handleAvailabilityTypeChange = (selectedOptions: string[]): void => {
    setGridSettingsItem('availabilityType', selectedOptions);
  };

  const handleAvailabilityHoursChange = (selectedOptions: string[]): void => {
    setGridSettingsItem('availabilityHours', selectedOptions);
  };

  const getSelectedOperatorValue = (): string => {
    const selectedOperator = operators.find((operator) => operator.id === operatorId);
    return selectedOperator ? selectedOperator.name : '';
  };
  const getSelectedDedicatedOperatorValue = (): string => {
    if (dedicatedOperatorId === 'none') return dedicatedOperatorId;

    const selectedOperator = operators.find((operator) => operator.id === dedicatedOperatorId);
    return selectedOperator ? selectedOperator.name : '';
  };

  return (
    <Box sx={{ px: '16px' }}>
      <ResettableSection sx={{ pr: '4px', mb: '8px' }} title="General filters" onReset={resetGeneralFilters} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <RadioButtonGroup buttons={CONTACT_ROLES_ITEMS} initialSelection={role} onChange={handleRoleChange} />
        {role === ContactRoles.TEAM && (
          <Autocomplete
            label="Team"
            color="primary"
            variant="outlined"
            items={teams.map(({ name, id }) => ({ value: id, name: name ?? '' }))}
            value={team}
            onChange={handleTeamChange}
          />
        )}
        <MultiAutocomplete
          label="Availability type"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={makeSelectItems(CONTACT_AVAILABILITY_TYPES as Array<string>)}
          value={availabilityType}
          onChange={handleAvailabilityTypeChange}
        />
        <MultiAutocomplete
          label="Available hours"
          color="primary"
          chipColor="primary"
          variant="outlined"
          items={makeSelectItems(CONTACT_AVAILABILITY_HOURS as Array<string>)}
          value={availabilityHours}
          onChange={handleAvailabilityHoursChange}
        />
        <Autocomplete
          label="Operator"
          color="primary"
          variant="outlined"
          items={operatorItems}
          disabledItems={operatorDisabledItems}
          value={getSelectedOperatorValue()}
          onChange={handleOperatorChange}
        />
        <Autocomplete
          label="Assigned operator"
          color="primary"
          variant="outlined"
          items={[{ value: 'none', name: 'None' }, ...operatorItems]}
          disabledItems={operatorDisabledItems}
          value={getSelectedDedicatedOperatorValue()}
          onChange={handleDedicatedOperatorChange}
        />
      </Box>
    </Box>
  );
};

export default GeneralFilters;
